<template>
  <div
    :aria-labelledby="id"
    class="AiCheckboxInput"
    :class="{
      [`AiCheckboxInput--${variant}`]: Boolean(variant),
      'AiCheckboxInput--error': errors.length > 0,
    }">
    <input
      :id="id"
      ref="input"
      v-model="currentValue"
      :name="id"
      :disabled="disabled"
      type="checkbox"
      @click="$emit('click')" />
    <ai-typo
      v-if="label"
      as="label"
      :for="id"
      variant="paragraph-03-regular"
      :color="disabled ? 'stratos-300' : errors.length ? 'red-700' : 'default'"
      class="AiCheckboxInput-label">
      {{ label }}
    </ai-typo>
  </div>
</template>

<script setup lang="ts">
import AiTypo from '../AiTypo/AiTypo.vue';

export interface AiCheckboxInputProps {
  modelValue: boolean;
  disabled?: boolean;
  id: string;
  variant?: 'positive' | 'inverse';
  label?: string;
  errors?: string[];
}

const props = withDefaults(defineProps<AiCheckboxInputProps>(), {
  label: undefined,
  variant: 'positive',
  errors: () => [],
});

type Emits = {
  (event: 'update:modelValue', value: boolean): void;
  (event: 'click'): void;
};

const emits = defineEmits<Emits>();

const input = ref<HTMLInputElement>();

defineExpose({ input });

const currentValue = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emits('update:modelValue', value);
  },
});
</script>

<style scoped lang="scss">
@use '@/assets/styles/utilities/colors';
@use '@/assets/styles/utilities/mixins';
@use '@/assets/styles/utilities/constants';

.AiCheckboxInput {
  display: block;
  text-wrap: nowrap;

  input {
    appearance: none;
    display: inline-block;

    @include mixins.rem-fallback(height, 24);
    @include mixins.rem-fallback(width, 24);

    margin: 0 constants.$inner-02 0 0;

    /* background-color only for content */
    background-clip: content-box;
    padding: 0.6rem;
    border: 0.2rem solid colors.$gold-900;
    background-color: transparent;

    transition: 0.2s ease-in-out;
    cursor: pointer;

    &:checked {
      background-color: colors.$gold-900;
    }

    &:focus-within {
      outline: 0.2rem solid colors.$gold-900;
      @include mixins.rem-fallback(outline-offset, 2);
    }

    &:disabled {
      border-color: colors.$gold-500;
      &:checked {
        background-color: colors.$gold-500;
      }
    }
  }

  &--inverse {
    input {
      border: 0.2rem solid colors.$gold-200;
      &:checked {
        background-color: colors.$gold-200;
      }

      &:focus-within {
        outline: 0.2rem solid colors.$gold-200;
      }
    }
  }

  &--error {
    input {
      border-color: colors.$red-700;
      &:checked {
        background-color: colors.$red-700;
      }

      &:focus-within {
        outline: 0.2rem solid colors.$red-700;
      }
    }
  }
}

.AiCheckboxInput-label {
  display: inline-block;
  vertical-align: top;
}
</style>
